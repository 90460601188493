import { memo } from 'react';
import {CellContext} from '@tanstack/react-table';
import {format} from 'date-fns';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {ICompanyWithCustomer} from '../CompaniesTable/buildCompaniesColumns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUser} from '@src/selectors/entities/users';

export const CreatedCell = memo(({row}: CellContext<ICompanyWithCustomer, ICompanyWithCustomer>) => {
	const createdAt = format(new Date(row.original.createdAt ? row.original.createdAt : ''), dateTimeFormat);
	const createdBy = useAppSelector(s => extractUser(s, row.original.createdBy));

	return (
		<>
			<span>{createdAt}</span>
			<br/>
			<span>{createdBy?.fullName}</span>
		</>
	);
});
