import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {store, persistor} from './store/appStore';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SocketConnector from './api/SocketConnector';
import GlobalUploader from '@tehzor/tools/core/GlobalUploader';
import '@tehzor/ui-components/src/index.less';
// import './main.less';
import './styles/main.less';
import Root, {IRootProps} from './containers/Root';
import {storeNotificationMessage} from '@src/actions/notifications/storeNotificationMessage';
import {addTempFile, deleteTempFile} from '@src/store/modules/entities/tempFile/actions';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import config from '@src/core/config';
import {wsConnector} from '@src/api/backend/wsConnector';
import {tokenUpdater} from '@tehzor/tools/api/updateTokens';
import {oldSocketConnector} from '@src/api/backend/oldWsConnector';

require('./utils/polyfills');
require('./utils/mathAddons');

const {apiUrl, newApiUrl, newWsApiUrl, appId} = config;

const socketConnector = SocketConnector.instance;
const globalUploader = GlobalUploader.instance;
const customLogout = async () => {
	await Promise.resolve(() => {
		store.dispatch({type: 'auth/logout'});
		store.dispatch({type: 'auth/clear-store'});
	});
};

const render = (Component: (props: IRootProps) => React.ReactElement) => {
	tokenUpdater.initialize(appId);
	// Инициализация модуля http-запросов
	if (newApiUrl) {
		httpRequests.initialize(newApiUrl, customLogout);
	}
	// Подключение по websocket к api
	if (newWsApiUrl) {
		wsConnector.connect(newWsApiUrl, undefined, undefined, customLogout);
	}

	if (apiUrl) {
		socketConnector.initialize(store, storeNotificationMessage);
		socketConnector.connect(apiUrl);
		oldSocketConnector.initialize(store, storeNotificationMessage);
		oldSocketConnector.connect(apiUrl);
	}

	globalUploader.initialize(store, addTempFile, deleteTempFile);
	const container = document.getElementById('app');
	const root = createRoot(container!);

	root.render(<Component
		store={store}
		persistor={persistor}
	/>);
};

render(Root);

// if (module.hot) {
// 	module.hot.accept('./containers/Root.js', () => {
// 		const NextRootContainer = require('./containers/Root').default;
// 		render(NextRootContainer);
// 	});
// }
