import { createContext, useCallback } from 'react';
import './EditableProblemTemplatesList.less';
import {ISavingProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplate';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {makeEmptyTemplate} from '@src/core/hooks/states/useEditableProblemTemplatesSetState';
import {
	LinkButton,
	EntitiesTable,
	TextField,
	IconButton,
	Checkbox,
	ReasonSelect,
	Select2,
	SelectOption,
	SelectPopup
} from '@tehzor/ui-components';
import {useAsyncFn} from 'react-use';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {CellProps, Column} from 'react-table';
import {useDispatchContext} from '../hooks/useEditableProblemTemplatesSet';
import config from '@src/core/config';
import {extractRegStandardsAsArray} from '@src/store/modules/entities/regulatoryStandards/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import IReason from '@tehzor/tools/interfaces/IReason';
import {extractObjectStagesAsArray} from '@src/store/modules/entities/objectStages/selectors';
import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

const externalLink = config.regStandardExternalLink;

interface IEditableProblemTemplatesListProps {
	templates: ISavingProblemTemplate[];
	label: string;
	disabled: boolean;
}

type ContextAction = ITableContextMenuAction<ISavingProblemTemplate & {id: string}>;

const DispatchActionCtx = createContext<(action: ContextAction) => void>(() => ({}));

const TextInputCell = ({
	row,
	rootField,
	fieldName
}: {rootField: string; fieldName: string} & CellProps<ISavingProblemTemplate>) => {
	const data = row.original;
	const dispatch = useDispatchContext();

	const handleChange = useCallback(
		(value: string) => {
			dispatch({
				type: 'update-array-item',
				index: row.index,
				field: rootField as any,
				value: {
					...data,
					[fieldName]: value
				}
			});
		},
		[row]
	);

	return (
		<TextField
			elementType="input"
			value={data[fieldName]}
			onChange={handleChange}
		/>
	);
};

const ModeratedCheckboxCell = ({row}: CellProps<ISavingProblemTemplate>) => {
	const data = row.original;
	const dispatch = useDispatchContext();

	const handleChange = useCallback(() => {
		dispatch({
			type: 'update-array-item',
			index: row.index,
			field: 'data',
			value: {
				...data,
				moderated: !data.moderated
			}
		});
	}, [row]);

	return (
		<Checkbox
			className={{
				root: 'object-page__fields-settings-checkbox',
				icon: 'object-page__fields-settings-checkbox-input'
			}}
			checked={data.moderated}
			onChange={handleChange}
		/>
	);
};

/**
 * Чекбокс "Критично"
 * @param row
 * @constructor
 */
const CriticalCheckboxCell = ({row}: CellProps<ISavingProblemTemplate>) => {
	const data = row.original;
	const dispatch = useDispatchContext();

	const handleChange = useCallback(() => {
		dispatch({
			type: 'update-array-item',
			index: row.index,
			field: 'data',
			value: {
				...data,
				critical: !data.critical
			}
		});
	}, [row]);

	return (
		<Checkbox
			className={{
				root: 'object-page__fields-settings-checkbox',
				icon: 'object-page__fields-settings-checkbox-input'
			}}
			checked={!!data.critical}
			onChange={handleChange}
		/>
	);
};

const DescriptionCell = (props: CellProps<ISavingProblemTemplate>) => (
	<TextInputCell
		rootField="data"
		fieldName="description"
		{...props}
	/>
);
const PrescriptionCell = (props: CellProps<ISavingProblemTemplate>) => (
	<TextInputCell
		rootField="data"
		fieldName="prescription"
		{...props}
	/>
);
const CategoryCell = (props: CellProps<ISavingProblemTemplate>) => (
	<TextInputCell
		rootField="data"
		fieldName="categoryId"
		{...props}
	/>
);
const FixDateCell = (props: CellProps<ISavingProblemTemplate>) => (
	<TextInputCell
		rootField="data"
		fieldName="fixDate"
		{...props}
	/>
);

const ReasonSelectCell = ({row}: CellProps<ISavingProblemTemplate>) => {
	const data = row.original;
	const dispatch = useDispatchContext();
	const regStandards = useAppSelector(extractRegStandardsAsArray);

	const handleChange = useCallback(
		(value: IReason | null) => {
			dispatch({
				type: 'update-array-item',
				index: row.index,
				field: 'data',
				value: {
					...data,
					reason: value
				}
			});
		},
		[row]
	);

	return (
		<ReasonSelect
			data={regStandards}
			value={data.reason}
			externalLink={externalLink}
			onChange={handleChange}
		/>
	);
};

const getStageName = (stages: IObjectStage[], stageId: ObjectStageIds | undefined) => {
	const found = stages.find(stage => stage.id === stageId);

	return found?.name;
};

const ObjectStageSelectCell = ({row}: CellProps<ISavingProblemTemplate>) => {
	const data = row.original;
	const dispatch = useDispatchContext();
	const stages = useAppSelector(extractObjectStagesAsArray);

	const handleChange = useCallback(
		(value: string) => {
			dispatch({
				type: 'update-array-item',
				index: row.index,
				field: 'data',
				value: {
					...data,
					stageId: value
				}
			});
		},
		[row]
	);

	return (
		<SelectPopup
			trigger={
				<div>
					<TextField
						elementType="div"
						value={getStageName(stages, data.stageId)}
					/>
				</div>
			}
		>
			<Select2
				value={data.stageId}
				onChange={handleChange}
			>
				{stages.map(stage => (
					<SelectOption
						key={stage.id}
						itemKey={stage.id}
						content={stage.name}
						style={{width: '100%'}}
					/>
				))}
			</Select2>
		</SelectPopup>
	);
};

const DeleteCell = ({row}: CellProps<ISavingProblemTemplate>) => {
	const editingDispatch = useDispatchContext();

	const handleClick = useCallback(() => {
		editingDispatch({type: 'delete-array-item', field: 'data', index: [row.index]});
	}, [row.index]);

	return (
		<IconButton
			type="inline-blue-accent"
			onClick={handleClick}
		>
			<i className="tz-delete" />
		</IconButton>
	);
};

const columns: Array<Column<ISavingProblemTemplate>> = [
	{
		id: 'description',
		Header: 'Описание',
		Cell: DescriptionCell,
		width: 100
	},
	{
		id: 'categoryId',
		Header: 'Вид работ',
		Cell: CategoryCell,
		width: 100
	},
	{
		id: 'reason',
		Cell: ReasonSelectCell,
		Header: 'Основание',
		width: 100
	},
	{
		id: 'fixDate',
		Cell: FixDateCell,
		Header: 'Срок устранения, дней',
		width: 100
	},
	{
		id: 'critical',
		Cell: CriticalCheckboxCell,
		Header: 'Критично',
		width: 50
	},
	{
		id: 'prescription',
		Header: 'Предписание',
		Cell: PrescriptionCell,
		width: 100
	},
	{
		id: 'stageId',
		Cell: ObjectStageSelectCell,
		Header: 'Стадия',
		width: 100
	},
	{
		id: 'moderated',
		Cell: ModeratedCheckboxCell,
		Header: 'Прошел модерацию',
		width: 100
	},
	{
		id: '_delete',
		Cell: DeleteCell,
		width: 78,
		disableResizing: true
	}
];

const withId = <T,>(data: T) => ({...data, id: Math.random().toString(36).substr(2, 5)});

export const EditableProblemTemplatesList = ({
	label,
	disabled,
	templates
}: IEditableProblemTemplatesListProps) => {
	const dispatch = useDispatchContext();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить шаблон?'
	);

	const handleAdd = useCallback(() => {
		dispatch({
			type: 'add-in-array',
			field: 'data',
			value: [withId(makeEmptyTemplate())]
		});
	}, []);

	const [, handleContextMenuAction] = useAsyncFn(async (action: ContextAction) => {
		if (action.type === 'delete' && (await getDeleteConfirmation())) {
			/**
			 * TODO в этом if не было кода и не было return
			 * надо разобраться почему
			 */
			return undefined;
		}
		return undefined;
	}, []);

	return (
		<div className="editable-problem-templates-list">
			<div className="editable-problem-templates-list__header">
				<div className="editable-problem-templates-list__header-title">{label}</div>
				<div className="editable-problem-templates-list__header-buttons">
					<LinkButton
						leftIcon={<i className="tz-plus-24" />}
						label="Добавить шаблон нарушения"
						disabled={disabled}
						onClick={handleAdd}
					/>
				</div>
			</div>
			<div className="editable-problem-templates-list__content">
				<DispatchActionCtx.Provider value={handleContextMenuAction}>
					<EntitiesTable
						columns={columns as any}
						data={templates as any}
					/>
				</DispatchActionCtx.Provider>
			</div>

			{deleteDialog}
		</div>
	);
};
