import * as types from './constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetCheckRecordStatusesResponse,
	requestCheckRecordStatuses
} from '@src/api/backend/checkRecordStatuses';
import {IState} from '../..';
import checkLoaded from '@tehzor/tools/core/checkLoaded';

export type IGetCheckRecordStatusesPayload = IGetCheckRecordStatusesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetCheckRecordStatusesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке статусов записей категорий чек листа');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список статусов записей категорий чек листов
 */
export const getCheckRecordStatuses = () =>
	checkLoaded<IState, IGetCheckRecordStatusesResponse, ApiAction>(
		state => state.entities.checkRecordStatuses,
		createApiAction<IGetCheckRecordStatusesResponse>(
			request,
			success,
			failure,
			requestCheckRecordStatuses
		)
	);
