import {IState} from '@src/store/modules';
import * as types from './constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetWorkAcceptanceStatusesResponse,
	requestWorkAcceptanceStatuses
} from '@src/api/backend/workAcceptanceStatuses';
import checkLoaded from '@tehzor/tools/core/checkLoaded';

export type IGetWorkAcceptanceStatusesPayload = IGetWorkAcceptanceStatusesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetWorkAcceptanceStatusesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при получении статусов приёмок работ');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает статусы приёмок работ
 */
export const getWorkAcceptanceStatuses = () =>
	checkLoaded<IState, IGetWorkAcceptanceStatusesResponse, ApiAction>(
		state => state.entities.checkRecordStatuses,
		createApiAction<IGetWorkAcceptanceStatusesResponse>(
			request,
			success,
			failure,
			requestWorkAcceptanceStatuses
		)
	);
