import * as React from 'react';
import {toast, ToastOptions, TypeOptions} from 'react-toastify';

export const addToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	type: TypeOptions = 'default',
	options?: ToastOptions
) => toast((
	<div className="custom-toast">
		{icon && (
			<div className="custom-toast__icon">
				{icon}
			</div>
			)}
		<div className="custom-toast__text">
			<div className="custom-toast__title">{title}</div>
			<div className="custom-toast__desc">{desc}</div>
		</div>
	</div>
	), {
		type,
		...options
	});

export const addInfoToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions
) => addToast(title, desc, undefined, 'info', options);

export const addSuccessToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions
) => addToast(title, desc, undefined, 'success', options);

export const addWarningToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions
) => addToast(title, desc, undefined, 'warning', options);

export const addErrorToast = (
	title?: string,
	desc?: string,
	icon?: React.ReactNode,
	options?: ToastOptions
) => addToast(title, desc, undefined, 'error', options);
